import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Attachcopies from './components/Attachcopies';

const attachcopies = (props,{ t }) => (
  <Container>
    <Attachcopies {...props} />
  </Container>
);

attachcopies.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(attachcopies);

const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "https://api.demoproject1.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://api.demoproject1.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://api.demoproject1.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://api.demoproject1.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://api.demoproject1.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://api.demoproject1.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  attaches:
    "https://api.demoproject1.dosystemsinc.com/images/sample_attaches_bulk_upload_file.csv",
  attachcopies:
    "https://api.demoproject1.dosystemsinc.com/images/sample_attachcopies_bulk_upload_file.csv",
};
export default configSampleFiles;
